import WebSetting from "@/types/WebSetting";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WebSetting = {
    name: "XanhEco ",
    tel: "",
    aboutus: "",
    email: "",
    address: "",
    title:"XanhEco "
};

const siteSetting = createSlice({
    name: "siteSetting",
    initialState,
    reducers: {
        updateSiteSetting: (state, action: PayloadAction<WebSetting>) => {
            // state = action.payload
            console.log("update site setting:",action.payload)
            Object.assign(action.payload,state)
        },
        
    },
});

export const { updateSiteSetting } = siteSetting.actions;
export default siteSetting.reducer;