import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoModalState {
  isVideoModalOpen: boolean;
  videoUrl: string;
}

const initialState: VideoModalState = {
  isVideoModalOpen: false,
  videoUrl: ""
};

const videoModalSlice = createSlice({
  name: 'videoModal',
  initialState,
  reducers: {
    toggleVideoModalOpen: (state,action: PayloadAction<string>) => {
      state.isVideoModalOpen = true;
      state.videoUrl = action.payload;
    },
    toggleVideoModalClose: (state) => {
      state.isVideoModalOpen = false;
      state.videoUrl="";
    },
  },
});

export const { toggleVideoModalOpen, toggleVideoModalClose } = videoModalSlice.actions;
export default videoModalSlice.reducer;