// rootReducer.ts
import { combineReducers } from "@reduxjs/toolkit";
import cartModalReducer from "./features/cartModalSlice";
import cartReducer from "./features/cartSlice";
import searchModalReducer from "./features/searchModalSlice";
import shopSidebarReducer from "./features/shopSidebarSlice";
import shopReducer from "./features/shopSlice";
import siteSettingReducer from "./features/siteSetting";
import videoModalReducer from "./features/videoModalSlice";
import wishlistModalReducer from "./features/wishlistModalSlice";
import wishlistReducer from "./features/wishlistSlice";
const rootReducer = combineReducers({
  videoModal: videoModalReducer,
  siteSetting: siteSettingReducer,
  shop: shopReducer,
  shopSidebar: shopSidebarReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  cartModal: cartModalReducer,
  searchModal: searchModalReducer,
  wishlistModal: wishlistModalReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
